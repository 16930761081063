import React from 'react'
import Helmet from 'react-helmet'
import { Navigator } from "./Navigator"

import './Layout-reset.scss'
import './Layout.scss'
export default class Template extends React.Component {
    render() {
        return (
            <div>
                <Helmet
                    title="Gabriel Jürgens Fotografía"
                    meta={[
                        { name: 'description', content: 'Sitio oficial del fotógrafo Gabriel Jürgens, basado en Buenos Aires Argentina' },
                        { name: 'keywords', content: 'Gabriel Jürgens, Jurgens, Fotografia, Buenos Aires' },
                    ]}
                />
                <Navigator scrollHidden={this.props.location.pathname === "/" ? 0 : -500}/>
                <div
                    style={{
                        margin: '0 auto',
                        padding: 0, /*'0px 1.0875rem 1.45rem'*/
                        paddingTop: 0,
                    }}
                >
                    {this.props.children}
                </div>

            </div>
        )
    }
}
