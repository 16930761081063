import React from 'react';
import Headroom from 'react-headroom';
import Link from 'gatsby-link'
import styles from './Navigator.module.scss';
import { graphql, StaticQuery } from 'gatsby'

export class Navigator extends React.Component {
    static DEFAULT_SCROLL_HIDDEN = 0;
    static DEFAULT_SIZE = 50;
    static DEFAULT_PIN_START = 400;
    static DEFAULT_UP_TOLERANCE = 0;
    static DEFAULT_DOWN_TOLERANCE = 50;

    STRUCTURE = {};

    getScrollHidden() {
        return this.props.scrollHidden ? this.props.scrollHidden : Navigator.DEFAULT_SCROLL_HIDDEN;
    }

    static getMenuItems = (structure) => {
        const getSubItems = (items, parentIndex) => {
            if(items === undefined) return [];
            return (
                <ul className={styles.navDropdown} key={`menuSubList_${parentIndex}`}>
                    {items.map((node, index) => {
                        return (
                            <li key={`menuSubItem_${parentIndex}-${index}`}>
                                <Link to={node.item.href} onClick={itemClickHandler}>{node.item.text}</Link>
                            </li>
                        )
                    })}
                </ul>
            );
        };

        const dropDownClickHandler = (event) => {
            const dropDownUl = event.target.parentNode.querySelector("ul");
            const currentStatus = dropDownUl.style.display;
            document.querySelectorAll(`.${styles.navDropdown}`).forEach(item => item.style.display = "none");
            dropDownUl.style.display = currentStatus === "block" ? "none" : "block";
            event.preventDefault();
            event.stopPropagation();
            event.nativeEvent.stopImmediatePropagation();
        };

        const itemClickHandler = (event) => {
            document.querySelector(`.${styles.navList}`).style.display = "";
            document.querySelector(`.${styles.navToggle}`).classList.remove(styles.navToggleActive);

            event.stopPropagation();
            event.nativeEvent.stopImmediatePropagation();
        }

        return (
            <ul className={styles.navList}>
                {structure.items.map((node, index) => {
                    let item;
                    if(node.items && node.items.length > 0) {
                        item = <a href={node.item.href} onClick={dropDownClickHandler} key={`menuLinkItem_${index}`}>{node.item.text}</a>
                    } else {
                        item = <Link to={node.item.href} onClick={itemClickHandler} key={`menuLinkItem_${index}`}>{node.item.text}</Link>
                    }
                    return (
                        <li key={`menuItem_${index}`}>
                            {[item].concat(getSubItems(node.items,index))}
                        </li>
                    )
                })}
            </ul>
        )
    };

    static handleNavToggle = (event) => {
        const navList = document.querySelector(`.${styles.navList}`);
        const navToggle = document.querySelector(`.${styles.navToggle}`);
        const navHeadRoom = document.querySelector(`#headRoom`);

        navHeadRoom.style.overflow = "auto";
        if(navList.style.display === "block") {
            navList.style.display = "none";
            navHeadRoom.style.height = "55px";
        } else {
            navHeadRoom.style.height = "100%";
            navList.style.display = "block";
        }
        navToggle.classList.toggle(styles.navToggleActive);
        event.preventDefault();
        event.stopPropagation();
    };

    static hideAllDropDowns () {
        document.querySelectorAll(`.${styles.navDropdown}`).forEach(item => item.style.display = "none");
    }


    ticking = false;
    last_known_scroll_position = 0;
    headRoomVisible = false;

    scrollHandler = () => {
        const HEAD_ROOM_ELEMENT = document.getElementById("headRoom");

        this.last_known_scroll_position = window.scrollY;
        if (!this.ticking) {
            window.requestAnimationFrame(() => {
                if(this.last_known_scroll_position > this.getScrollHidden()) {
                    if(!this.headRoomVisible){
                        HEAD_ROOM_ELEMENT.style.transition = "all 0.2s ease-in-out";
                        HEAD_ROOM_ELEMENT.style.transform = "translateY(0%)";
                        this.headRoomVisible = true;
                        window.removeEventListener('scroll', this.scrollHandler);
                    }
                }/* else {
                    if(this.headRoomVisible){
                        HEAD_ROOM_ELEMENT.style.transition = "all 0.2s ease-in-out";
                        HEAD_ROOM_ELEMENT.style.transform = "translateY(-100%)";
                        this.headRoomVisible = false;
                    }
                }*/

                this.ticking = false;
            });
        }
        this.ticking = true;
    };

    componentDidMount() {
        document.body.addEventListener("click",Navigator.hideAllDropDowns);
        window.addEventListener('scroll', this.scrollHandler);
        this.scrollHandler();
    }


    componentDidUpdate(prevProps, prevState) {
        this.scrollHandler();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if(nextProps.scrollHidden === 0) {
            const HEAD_ROOM_ELEMENT = document.getElementById("headRoom");
            if(this.headRoomVisible){
                HEAD_ROOM_ELEMENT.style.transition = "all 0.2s ease-in-out";
                HEAD_ROOM_ELEMENT.style.transform = "translateY(-100%)";
                this.headRoomVisible = false;
                window.addEventListener('scroll', this.scrollHandler);
            }
        }
    }

    render() {
        return (
            <StaticQuery query={
                graphql`
                query Layout {
                    allCategories(sort:{fields:[data___order], order:ASC}) {
                        edges {
                            node {
                                id
                                category
                                categoryGroup
                                categoryGroupName
                                categoryGroupSlug
                                type
                                data {
                                    slug
                                    name
                                    order
                                }
                            }
                        }
                    }
                }
               `
                }
                render={data => {

                    let categories = {};

                    const formatGroupCategoryName = (group) => {
                        const parts = group.split("_");
                        //let name = "";
                        for(let i = 0; i < parts.length; i++) {
                            let chars = parts[i].split("");
                            chars[0] = chars[0].toUpperCase();
                            parts[i] = chars.join("");
                        }

                        return parts.join(" ");
                    };

                    const getCategoryGroupName = (node) => node.node.categoryGroupName ? node.node.categoryGroupName : formatGroupCategoryName(node.node.categoryGroup);

                    if(data && data.allCategories && data.allCategories.edges instanceof Array) {
                        categories = data.allCategories.edges
                            .reduce((memo, node)=>{
                                const type = node.node.type;
                                const categoryGroup = node.node.categoryGroup;

                                if(memo[type] === undefined) memo[type] = [];
                                if(categoryGroup === "hidden") {
                                    memo[type].push({
                                        item:{text:node.node.data.name, href:node.node.data.slug}
                                    });
                                } else {
                                    let inserted = false;
                                    for(let i = 0; i < memo[type].length; i++) {
                                        if(memo[type][i].item.text === (getCategoryGroupName(node))) inserted = true;
                                    }
                                    if(!inserted) memo[type].push({
                                        item:{text:getCategoryGroupName(node), href:node.node.categoryGroupSlug ? node.node.categoryGroupSlug : "/" + node.node.type + "/" + node.node.categoryGroup + "/"}
                                    });
                                }
                                return memo;
                            },{});
                    }

                    const STRUCTURE = {
                        logo: <Link to={"/"}><h2>GABRIEL JÜRGENS | fotografía</h2></Link>,
                        items: [
                            {item: {text: "Inicio", href:"/"}},
                            {item: {text: "Servicios", href:"/servicios/"}},
                            {
                                item: {text: "Lugares", href:"#!"},
                                items: categories.lugares
                            },
                            {
                                item: {text: "Portfolio", href:"#!"},
                                items: categories.portfolio
                            },
                            {item: {text: "Contacto", href:"/contacto/"}}
                        ]
                    };

                    return (
                        <Headroom
                            id={"headRoom"}
                            style={{
                                position:"fixed",
                                transform:"translateY(-100%)",
                                transition:"all 0.2s ease-in-out",
                                zIndex: 20
                            }}
                            pinStart={Navigator.DEFAULT_PIN_START}
                            upTolerance={Navigator.DEFAULT_UP_TOLERANCE}
                            downTolerance={Navigator.DEFAULT_DOWN_TOLERANCE}
                            className={styles.wrapper}
                            wrapperStyle={{
                                marginBottom:`-${Navigator.DEFAULT_SIZE}px`,
                                height:`${Navigator.DEFAULT_SIZE}px !important`,
                                overflow:"hidden"
                            }}
                        >
                            <section className={styles.navigation} style={{height:`${Navigator.DEFAULT_SIZE}px`,overflow:"hidden"}}>
                                <div className={styles.navContainer}>
                                    <div className={styles.brand}>
                                        {STRUCTURE.logo}
                                    </div>
                                    <nav className={styles.navElement} onClick={Navigator.handleNavToggle} onKeyPress={Navigator.handleNavToggle} role="presentation">
                        <div className={styles.navMobile}><a className={styles.navToggle} href="#!"><span>{}</span></a></div>
                                        {Navigator.getMenuItems(STRUCTURE)}
                                    </nav>
                                </div>
                            </section>
                        </Headroom>
                    )
                }}
            >

            </StaticQuery>
        );
    }

}
